import {
  Button,
  HStack,
  Heading,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { AttestorEnvironment, AttestorStatusTableProps, EndpointStatus } from '../models';
import StatusBadge from './StatusBadge';
import BitcoinBadge from './BitcoinBadge';
import { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import { analyzeItemStatus } from 'utilities';

function AttestorStatusTable({ title, data, openModal }: AttestorStatusTableProps) {
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [unhealthyItems, setUnhealthyItems] = useState<typeof data>([]);

  const statusAnalysis = analyzeItemStatus(data);

  const toggleTableVisibility = () => {
    setIsTableVisible((prevState) => !prevState);
  };
  const headingColor = useColorModeValue('black', 'white');

  useEffect(() => {
    const currentUnhealthyItems = data.filter((item) => item.status !== EndpointStatus.HEALTHY);
    setUnhealthyItems(currentUnhealthyItems);
  }, [data]);

  return (
    <VStack width={'100%'} alignItems={'flex-start'}>
      <HStack>
        <Heading as="h2" fontSize={{ base: '2xl', md: '3xl' }} color={headingColor}>
          {title.charAt(0).toUpperCase() + title.slice(1)}
        </Heading>
        <Button onClick={toggleTableVisibility} variant="link" size="lg">
          {isTableVisible ? (
            <ChevronDownIcon w={6} h={6} color={headingColor} />
          ) : (
            <ChevronRightIcon w={6} h={6} color={headingColor} />
          )}
        </Button>
      </HStack>
      {(unhealthyItems.length > 0 || isTableVisible) && (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Operator</Th>
              <Th>Name</Th>
              <Th>Version</Th>
              <Th>Reach Bitcoin?</Th>
              <Th>EVM Healthy?</Th>
              <Th>Noise Ready?</Th>
              <Th>VSR</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {(isTableVisible ? data : unhealthyItems).map((item) => (
              <Tr key={item.operator}>
                <Td w="25%">{item.operator}</Td>
                <Td w="45%">{item.name}</Td>
                <Td w="25%">{item.version}</Td>
                <Td w="25%">
                  <BitcoinBadge status={item.canTalkToBitcoin} />
                </Td>
                <Td w="25%">
                  <BitcoinBadge status={item.evmHealth} />
                </Td>
                <Td w="25%">
                  <BitcoinBadge status={item.canTalkOverNoise} />
                </Td>
                <Td w="25%">
                  <BitcoinBadge status={item.VSR} />
                </Td>
                <Td w="25%">
                  <a
                    href={`${item.domain}/app/health`}
                    target="_blank"
                    rel="noopener noreferrer"
                    aria-label="Health"
                  >
                    <StatusBadge status={item.status} />
                  </a>
                </Td>
                <Td w="15%">
                  <Button onClick={() => openModal(item, title as AttestorEnvironment)}>
                    Details
                  </Button>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </VStack>
  );
}

export default AttestorStatusTable;
